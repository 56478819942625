.syberry-logo {
  &:hover {
    .animated {
      animation: flashAnimation 1.1s ease-in;

      &-1 {
        animation-delay: 0s;
      }
      &-2 {
        animation-delay: 0.15s;
      }
      &-3 {
        animation-delay: 0.3s;
      }
      &-4 {
        animation-delay: 0.45s;
      }
    }
  }
}

@keyframes flashAnimation {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
