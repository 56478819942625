.grecaptcha-badge {
  visibility: hidden !important;
}

.react-player iframe {
  border-radius: 8px;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    scrollbar-gutter: stable;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.link-green {
  @apply italic text-green-7 no-underline font-semibold duration-300 transition-colors hover:text-purple-5;
}

.intro-mask {
  mask-image: linear-gradient(180deg, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0) 78%);
}

.locations-intro-mask {
  mask-image: linear-gradient(180deg, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 45%, rgba(0, 0, 0, 0) 95%);
}

.lg-intro-mask {
  mask-image: linear-gradient(180deg, rgb(0, 0, 0) 35%, rgb(0, 0, 0) 35%, rgba(0, 0, 0, 0) 70%);
}

.left-right-mask {
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0.8) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

@tailwind base;
@tailwind components;
@tailwind utilities;
